/* BACK TO TOP */

.backToTop {
  z-index: 999;
  height: 40px;
  width: 40px;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 20px;
  right: 20px;
  background-color: #ffffffb4;
  border: 1px solid #db635c;
  border-radius: 50%;
  transition: 0.3s;
  transform: scale(var(--scale, 1));
  transition: transform 0.25s;
  cursor: pointer;
}
.backToTop:hover {
  border: 1px solid #be2b26;
  --scale: 1.2;
}
.backToTop a {
  color: black;
}
