.home {
  display: flex;
  flex-direction: column;
  gap: 150px;
}

.subsection {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

/* HEADER */

.header {
  margin-top: 50px;
  background-color: white;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100vh - 50px);
}

@media (min-width: 768px) {
  .header {
    height: 100%;
  }
}

.headerPresentation {
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
}

@media (min-width: 768px) {
  .headerPresentation {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    justify-content: center;
  }
}

.imgPresentation {
  margin: 0 auto;
  width: 250px;
  border-radius: 50%;
}

.textPresentation {
  text-align: start;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.headerName {
  /* font-size: 35px; */
  color: black;
  line-height: 35px;
}
.headerProfession {
  color: #db635c;
  font-weight: 400;
  font-size: 25px;
  max-width: 300px;
  line-height: 30px;
  text-align: center;
}

@media (min-width: 768px) {
  .headerProfession {
    text-align: start;
  }
}

/* ANIMATION HEADER */

.headerName {
  animation-duration: 3s;
  animation-name: slidein;
}
@keyframes slidein {
  from {
    color: white;
  }
  to {
    color: black;
  }
}
.headerProfession {
  animation-duration: 3s;
  animation-name: slidein-sub;
}
@keyframes slidein-sub {
  from {
    color: white;
  }
  to {
    color: #db635c;
  }
}

/* PROFILE */

.sectionBackgroundProfile {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.profileContainer {
  color: black;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

@media (min-width: 1000px) {
  .profileContainer {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 1200px) {
  .profileContainer {
    gap: 100px;
  }
}

.textProfileContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
}

@media (min-width: 1000px) {
  .textProfileContainer {
    max-width: 700px;
    gap: 50px;
  }
}

.textProfile {
  text-align: justify;
  width: 100%;
}

.profileSkills {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

@media (min-width: 1000px) {
  .profileSkills {
    width: 600px;
  }
}

.profileSkillstext {
  font-size: 20px;
  font-style: italic;
}

@media (min-width: 768px) {
  .profileSkillstext {
    font-size: 22px;
  }
}

.profileSkillstext li {
  line-height: 30px;
}

/* Software SECTION*/

.sectionBackgroundSkills {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.containerCardSoftware {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 30px;
}

/* EXPERIENCE SECTION */

.sectionExperience {
  min-height: 900px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 50px;
}

.sectionExperience p {
  text-align: justify;
}

.experienceContainer {
  max-width: 1100px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.imageAndLinkExperienceContainer {
  position: relative;
  display: inline-block;
  overflow: hidden;
}

.imageResponsiveExperience {
  width: 100%;
  height: auto;
  transition: filter 0.3s ease;
}

.imageAndLinkExperienceContainer:hover .imageResponsiveExperience,
.experienceLink:hover ~ .imageResponsiveExperience {
  filter: brightness(0.5);
}

.experienceLink {
  font-size: 24px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  background-color: #db625ce1;
  padding: 20px 60px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease;
  text-align: center;
}

.experienceLink:hover {
  background-color: #db635c;
}

/* Applications */
.applicationExperience {
  display: flex;
  justify-content: space-between;
  gap: 5px;
}

@media (min-width: 768px) {
  .applicationExperience {
    justify-content: space-evenly;
  }
}

.applicationExperience p {
  font-size: 20px;
  text-align: center;
  font-style: italic;
}

@media (min-width: 768px) {
  .applicationExperience p {
    font-size: 22px;
  }
}
