html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  background-color: white;
}

h1 {
  font-size: 30px;
}
@media (min-width: 768px) {
  h1 {
    font-size: 40px;
  }
}
h2 {
  font-size: 26px;
}
@media (min-width: 768px) {
  h2 {
    font-size: 32px;
  }
}
h3 {
  font-size: 22px;
}
@media (min-width: 768px) {
  h3 {
    font-size: 28px;
  }
}

h1,
h2,
h3,
h4,
h5,
p,
li,
ul,
a {
  margin: 0;
  padding: 0;
  line-height: 24px;
}
a {
  text-decoration: none;
}
ul,
li,
a {
  list-style: none;
}
@keyframes sweep {
  0% {
    opacity: 0;
    transform: translateX(-10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

div,
summary {
  animation: sweep 0.5s ease-in-out;
}
