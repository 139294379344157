.footerContact {
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 50px;
  justify-content: center;
  align-items: center;
}

.textFooterContact {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
}

.textFooterContact {
  font-size: 22px;
}

.buttonContact {
  background-color: #db635c;
  color: white;
  width: 320px;
  height: 60px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: solid 1px #db635c;
  border-radius: 50px;
  cursor: pointer;
  transition: 0.4s;
}

.buttonContact:hover {
  background-color: #c7514b;
  /* font-size: 18px; */
}
