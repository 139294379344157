.container-component {
  max-width: 1100px;
  padding: 0 0.8rem;
  margin: 0 auto;
  height: 100%;
}

@media (min-width: 780px) {
  .container-component {
    padding: 0 1.3rem;
  }
}
